@tailwind base;
@tailwind components;
@tailwind utilities;

.de_ancient {
    background: url(../public/img/map_sprites.png) -0px -0px no-repeat;
    width: 40px;
    height: 40px
}

.de_anubis {
    background: url(../public/img/map_sprites.png) -40px -0px no-repeat;
    width: 40px;
    height: 40px
}

.de_cache {
    background: url(../public/img/map_sprites.png) -80px -0px no-repeat;
    width: 40px;
    height: 40px
}

.de_dust2 {
    background: url(../public/img/map_sprites.png) -120px -0px no-repeat;
    width: 40px;
    height: 40px
}

.de_cbble {
    background: url(../public/img/map_sprites.png) -160px -0px no-repeat;
    width: 40px;
    height: 40px
}

.de_inferno {
    background: url(../public/img/map_sprites.png) -200px -0px no-repeat;
    width: 40px;
    height: 40px
}

.de_mirage {
    background: url(../public/img/map_sprites.png) -240px -0px no-repeat;
    width: 40px;
    height: 40px
}

.de_nuke {
    background: url(../public/img/map_sprites.png) -280px -0px no-repeat;
    width: 40px;
    height: 40px
}

.de_overpass {
    background: url(../public/img/map_sprites.png) -320px -0px no-repeat;
    width: 40px;
    height: 40px
}

.de_train {
    background: url(../public/img/map_sprites.png) -360px -0px no-repeat;
    width: 40px;
    height: 40px
}

.de_tuscan {
    background: url(../public/img/map_sprites.png) -400px -0px no-repeat;
    width: 40px;
    height: 40px
}

.de_vertigo {
    background: url(../public/img/map_sprites.png) -440px -0px no-repeat;
    width: 40px;
    height: 40px
}

.cs_default {
    background: url(../public/img/map_sprites.png) -480px -0px no-repeat;
    width: 40px;
    height: 40px
}

.btn {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    border-radius: .5rem;
    border-style: none;
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.25rem
}
